<template>
  <v-container>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>{{ loading ? "Student" : studentName }} Result</h1>
      </v-col>
    </v-row>

    <highcharts :options="chartOptions" class="mb-4"></highcharts>

    <v-data-iterator :items="results" hide-default-footer>
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="result in props.items"
            :key="result.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                <span
                  >{{ result.test_name }} -
                  <span class="green--text">{{ result.test_score }}</span></span
                >
              </v-card-title>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item
                  v-for="subtest in result.subtests"
                  :key="subtest.name"
                >
                  <v-list-item-content>{{ subtest.name }}</v-list-item-content>
                  <v-list-item-content class="align-end">
                    {{ subtest.score }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Test from "@/services/test";
import Student from "@/services/student";
import { Chart } from "highcharts-vue";

export default {
  name: "Result.Student.Result",
  data() {
    return {
      results: [],
      loading: true,
      student: {
        user: {
          name: ""
        }
      },
      chartData: []
    };
  },
  computed: {
    studentId() {
      return this.$route.params.student_id;
    },
    studentName() {
      return this.student.user.name;
    },
    chartOptions() {
      return {
        chart: {
          type: "column",
          scrollablePlotArea: {
            minWidth: 1500,
            scrollPositionX: 1
          }
        },
        title: {
          text: `${this.studentName} Test Score`
        },
        xAxis: {
          type: "category"
        },
        yAxis: {
          title: {
            text: "Score"
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              format: "{point.y}"
            }
          }
        },
        series: [
          {
            name: `${this.studentName} Test Score`,
            colorByPoint: true,
            data: this.chartData
          }
        ]
      };
    }
  },
  components: {
    highcharts: Chart
  },
  created() {
    this.setNavbarTitle("Student Result");
    this.getResults();
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getResults() {
      this.loading = true;
      this.results = [];
      const [results, student] = await Promise.all([
        Test.studentTestResult(this.studentId),
        Student.find(this.studentId)
      ]);
      this.results = results.data.data;
      this.student = student.data.data;
      this.chartData = results.data.data.map(
        ({ test_name: name, test_score: y }) => ({
          name,
          y
        })
      );
      this.loading = false;
    }
  }
};
</script>
